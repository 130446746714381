$body-bg: #f2f2f2;
$primary: #3587d8;
$enable-responsive-font-sizes: true;

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$poppins-font: "Poppins", -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
  helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji,
  segoe ui symbol;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $poppins-font;
  font-weight: 500;
  line-height: 1.2;
}

span,
p,
button,
input,
strong,
li,
div {
  font-family: $poppins-font;
}

strong {
  font-weight: 500;
}

.shadow-1 {
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.shadow-2 {
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.shadow-3 {
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.card {
  border-radius: 0.5rem;
  @extend .shadow-2;
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  &-header {
    padding: 1rem 1.25rem;
    background-color: transparent;
    border-bottom: none;
  }
  &-body {
    padding: 0;
    padding-bottom: 1rem;
  }
}

.navbar {
  @extend .shadow-3;
}
.card-header-tabs {
  margin: 0;
}
.list-group {
  &-item {
    border: none;
  }
}

@include media-breakpoint-down(sm) {
  .d-xs-none {
    display: none !important;
  }
  * {
    font-size: 16px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-primary {
  color: white !important;
}
